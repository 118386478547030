const processEnv = import.meta.env;

const booleanifyEnvValue = (value?: string) =>
  Boolean(value && value.toLowerCase() !== 'false');
const stringOrNullEnvValue = (value?: string) => value || null;

export const BUILD_YEAR = import.meta.env.VITE_DEFINED_BUILD_YEAR;
export const VITE_ENVIRONMENT = stringOrNullEnvValue(
  processEnv.VITE_ENVIRONMENT,
);
export const VITE_COMMIT_HASH = stringOrNullEnvValue(
  globalThis.location.origin.includes('//localhost:')
    ? '__DEV__'
    : processEnv.VITE_COMMIT_HASH,
);

export const VITE_MAPBOX_KEY = stringOrNullEnvValue(processEnv.VITE_MAPBOX_KEY);

export const VITE_GOOGLE_ANALYTICS_KEY = stringOrNullEnvValue(
  processEnv.VITE_GOOGLE_ANALYTICS_KEY,
);

export const VITE_DEBUG_ERROR_REPORTING = booleanifyEnvValue(
  processEnv.VITE_DEBUG_ERROR_REPORTING,
);
export const VITE_SENTRY_DSN = stringOrNullEnvValue(processEnv.VITE_SENTRY_DSN);

// export const PUBLIC_URL = processEnv.PUBLIC_URL;
