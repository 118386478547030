import clsx from 'clsx';
import { forwardRef, Ref } from 'react';
import { env } from 'src/api/hosts';
import { IconName, ICONS, isIconName } from 'src/components/common/Icon';
import './Icon.scss';

interface Props {
  name: IconName;
  className?: string;
  hideIfEmpty?: boolean;
}

function FallbackIcon({
  className,
  iconName,
}: {
  className?: string;
  iconName?: string;
}) {
  return (
    <i className={clsx(['the-icon', className])}>
      {env === 'prd' ? (
        <ICONS.CircleOutline />
      ) : (
        `[ICON MISSING${iconName ? `: ${iconName}` : ''}]`
      )}
    </i>
  );
}

function Icon(
  { name, className, hideIfEmpty }: Props,
  ref: Ref<HTMLSpanElement>,
) {
  if (!isIconName(name)) {
    return hideIfEmpty ? null : (
      <FallbackIcon className={className} iconName={name} />
    );
  }

  const IconComponent = ICONS[name];

  return (
    <i ref={ref} className={clsx(['the-icon', className])}>
      <IconComponent />
    </i>
  );
}

export default forwardRef(Icon);
