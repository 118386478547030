function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M17.44 8.36H6.38a3.17 3.17 0 0 0-3.17 3.18v8.08a3.17 3.17 0 0 0 3.17 3.17h11.06a3.17 3.17 0 0 0 3.18-3.17v-8.08a3.18 3.18 0 0 0-3.18-3.18zm-5.53 11a7.47 7.47 0 0 1-6.52-3.82 7.47 7.47 0 0 1 13.05 0 7.5 7.5 0 0 1-6.53 3.86zm0-.96a2.82 2.82 0 1 0 0-5.64 2.82 2.82 0 0 0 0 5.64zM16 4.23a5.55 5.55 0 0 0-7.84 0L7 3a7.25 7.25 0 0 1 10.23 0zm-2.5 2.52a2 2 0 0 0-2.78 0l-1.21-1.2a3.67 3.67 0 0 1 5.2 0z"
      />
    </svg>
  );
}

export default Icon;
