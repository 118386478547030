function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M1.31 7.16V3h21.38v4.2zM5.1 14.1V9.9h13.8v4.2zM8.88 21v-4.2h6.24V21z"
      />
    </svg>
  );
}

export default Icon;
